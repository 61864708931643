.remove {
  ul {
    list-style: none;
    padding-left: 0;
    @include flex-column-wrap;
    align-items: center;

    li {
      margin: 10px 0;

      button {
        cursor: pointer;
        background: transparent;
        color: $black;
        border: 0;
        outline: 0;
        transition: color .2s;
        display: flex;
        justify-content: center;
        align-items: center;

        .check-case {
          height: 1.6rem;
          width: 1.6rem;
          display: inline-block;
          border: solid $black 1px;
          border-radius: 2px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
          transition: border-color .2s;

          .check-content {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: color .2s;

            svg {
              width: 80%;
              height: 80%;
            }
          }
        }

        &.removed {
          text-decoration: line-through;
          color: $light-grey;

          .check-case {
            border-color: $light-grey;

            .check-content {
              opacity: 0;
            }
          }


        }

        &:hover {
          color: $grey;

          .check-case {
            border-color: $grey;

            .check-content {
              color: $grey;
            }
          }
        }
      }
    }
  }
}