.waiting {
  .customer:before {
    background: $red;
  }
}

.coming {
  .customer:before {
    background: $green;
  }
}

.past {
  .customer:before {
    background: $grey;
  }
}

.customer {
  @include flex-wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: background .2s;
  padding: 15px 0;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 5px;
    height: 100%;
    transition: background .2s;
  }

  .date {
    @include flex-column-wrap;
    justify-content: center;
    align-items: center;
    width: 30%;

    @include for-tablet {
      width: 140px;
    }

    @include for-large-screen {
      width: 18%;
    }

    font-family: League Spartan;
    text-align: center;
    font-size:2.5rem;

    span {
      font-size: 2.5rem;
    }
  }

  .infos {
    width: 60%;

    @include for-tablet {
      width: calc(90% - 140px);
    }

    @include for-large-screen {
      width: 72%;
    }

    //padding-right:20px;
    p {
      width: 100%;
      word-break: break-all;
    }

    .copy {
      max-width: 100%;
      cursor: pointer;
      width: max-content;
      opacity: 1;
      transition: color .2s;

      &:hover {
        color: $light-grey;
      }

      span {
        color: $green;
      }
    }
  }

  .actions {
    @include flex-column-wrap;
    width: 10%;

    @include for-large-screen {
      width: calc(10% - 5px);
      margin-right: 5px;
    }

    position:relative;
    z-index:2;
    justify-content: center;

    li {
      cursor: pointer;
      color: white;
      background: $black;
      height: 3.5rem;
      @include flex-wrap;
      justify-content: center;
      align-items: center;
      transition: background .2s, color .2s;
      width: 100%;

      &:hover {
        background: $white !important;
      }

      svg {
        height: 1.6rem;
      }

      &.action-check {
        background: $green;

        &:hover {
          color: $green;
        }
      }

      &.action-edit {
        background: $orange;

        &:hover {
          color: $orange;
        }
      }

      &.action-remove {
        background: $red;

        &:hover {
          color: $red;
        }
      }
    }
  }

  .copy-input {
    position: absolute;
    left: -1000vw;
  }

  .date-editor {
    @include popup;

    form {
      

      input {
        &[type=datetime-local] {
          border-radius: 5px;
          padding: 5px;
          border: solid 1px $grey;
          outline: 0;
          font-family: Hind;
          font-size: inherit;
          margin: 20px 0;

          &:focus {
            border-color: $orange;
            border-width: 2px;
            margin: 19px 0;
          }
        }

        &[type=submit] {
          @include button;
          background: $orange;
          border-color: $orange;

          &:hover {
            background: transparent;
            color: $orange;
          }
        }
      }
    }
  }

  &:hover {
    background: $black;

    &:before {
      background: $black;
    }

    p {
      color: $white;
    }

    .date {
      color: $white;

      span {
        color: $white;
      }
    }
  }
}