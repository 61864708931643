.success-list {
  pointer-events: none;
  width: 100%;
  @include flex-wrap;
  justify-content: center;
  position: fixed;
  top: 82vh;
  z-index: 4;

  .success {
    width: max-content;
    @include flex-wrap;
    background-color: $green;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    padding: 10px 30px;
    color: white;
    position: absolute;
    opacity: 0;
    top: 5vh;
    transition: opacity .3s, top .3s;
    max-width: 90%;

    &.visible {
      opacity: 1;
      top: 0;
    }
  }
}