.prospects-list {
  width: 100%;
  max-width: 1000px;
  @include flex-column-wrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  >li {
    width: 95%;
    margin: 10px 0;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    border: solid 1px $light-grey;
    @include flex-column-wrap;
    transition: border-color .5s;

    div {
      @include flex-wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include for-large-screen {
        flex-direction: row;
      }

      p {
        margin-bottom: 5px;

        @include for-large-screen {
          margin-bottom: 0;
          margin-right: 20px;
        }
      }

      .prospects-actions {
        @include flex-wrap;

        li {
          margin: 0 5px;

          >* {
            background-color: $blue;
            color: white;
            border: 0;
            padding: 0;
            @include flex-wrap;
            height: 2.8rem;
            width: 2.8rem;
            border-radius: 99999px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: background-color .1s, color .1s;

            svg {
              height: 1.6rem;
            }

            &:hover {
              background-color: transparent;
              color: $blue;
            }
          }

          &.trash {
            >button {
              background-color: $red;

              &:hover {
                background-color: transparent;
                color: $red;
              }
            }
          }
        }
      }
    }

    .commentaire {
      @include flex-wrap;
      text-align: center;
      justify-content: center;
      margin-top: 20px;
      background-color: $light-grey;
      padding: 10px 20px;
      border-radius: 5px;

      @include for-large-screen {
        padding: 10px 40px;
      }
    }

    &:hover {
      border-color: $grey;
    }
  }

  .mail-editor {
    @include popup;

    h3 {
      margin-bottom: 20px;
    }

    div {
      >p {
        margin: 0 0 10px 0;
      }

      form {
        @include form;
      }
    }
  }
}