/* Fonts */

/* Paragraphes */
@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Regular.ttf);
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-SemiBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Bold.ttf);
  font-weight: 800;
}

/* Subtitles */
@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Regular.otf);
}

@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Italic.otf);
  font-style: italic;
}

/* Titles */
@font-face {
  font-family: League Spartan;
  src: url(../assets/fonts/LeagueSpartan-Bold.otf);
}

/* Colors */

$red: #e21617;
$green: #50C878;
$grey: #777;
$black: #191919;
$white: #f6f6f6;
$orange: #EC9706;
$blue: #186DB6;
$light-grey: #ccc;

/* Mixins */

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-column-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

@mixin button {
  padding: 10px 20px;
  border: solid 2px;
  color: white;
  font-size: inherit;
  border-radius: 5px;
  transition: background .2s, color .2s;
  cursor: pointer;
}

@mixin popup {
  @include flex-wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 0;
  z-index: 3;
  transition: opacity .5s;
  opacity: 0;

  > :nth-child(1) {
    background-color: $white;
    max-width: 800px;
    width: 90%;
    @include flex-column-wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    top: 100px;
    transition: top .5s;
    border-radius: 5px;
  }

  &.visible {
    opacity: 1;

    > :nth-child(1) {
      top: 0;
    }
  }
}

@mixin form {
  @include flex-wrap;
  justify-content: space-between;
  width: 100%;

  .form-content {
    width: 100%;
    @include flex-column-wrap;

    label {
      cursor: pointer;
      text-align: left;
      width: max-content
    }

    textarea {
      height: calc(8*1.6rem);
    }

    input,
    textarea {
      width: 100%;
      padding: 5px 10px;
    }

    &.mid-width {
      @include for-large-screen {
        width: calc(50% - 10px);
      }

      @include for-tablet() {
        width: calc(50% - 10px);
      }

    }

    margin-bottom:10px;
  }

  input[type=submit] {
    @include button;
    background: $green;
    border-color: $green;
    margin: 20px auto 0;
    min-width: 250px;

    &:hover {
      background: transparent;
      color: $green;
    }
  }
}

@mixin for-large-screen {
  @media (min-width: 1001px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 550px) {
    @content;
  }
}

/* General */
#root {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  width: 100%;

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    align-items: center;
    color: $black;
    background-color: $white;
    font-family: Hind;
    font-size: 1.6rem;

    * {
      box-sizing: border-box;
      font-size: 1.6rem;
    }

    main {
      @include flex-column-wrap;
      justify-content: center;
      padding: 30px 0;
    }

    svg {
      pointer-events: none;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    textarea {
      resize: none;
      font-family: Hind;
      font-size: 1.6rem;
    }

    input {
      font-family: Hind;
    }
  }
}