.login-panel {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  color: white;
  left: 0;
  top: 0;
  margin: 0;
  max-width: none;
  pointer-events: all;
  transition: opacity .3s;

  form {
    @include flex-column-wrap;
    justify-content: center;
    align-items: center;

    label {
      font-size: 1.8rem;
      font-weight: bold;
      cursor: pointer;
    }

    input {
      margin-bottom: 5px;
      margin-top: 15px;
      padding: 5px 10px;
      border: 0;
      outline: 0;
      border-radius: 5px;
      text-align: center;
      font-family: Hind;
      font-size: inherit;

      &:focus {
        outline: solid 2px $red;
      }
    }

    .error-red {
      color: $red;
    }

    button {
      @include button;
      background: transparent;
      border-color: $white;
      margin-top: 30px;
      font-size: 1.6rem;
      font-family: Hind;

      &:hover {
        background: $white;
        color: $black;
      }
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }
}