.waiting,
.coming,
.past,
.prospects,
.login-panel {
  width: 100%;
  @include flex-column-wrap;
  justify-content: center;
  align-items: center;
  margin: 10px auto 40px;
  max-width: 1000px;

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-family: League Spartan;
    font-size: 1.6rem;
    margin-bottom: 40px;
  }

  .appointments {
    width: 100%;
    border: solid 1px $light-grey;
    border-left: 0;
    border-right: 0;

    @include for-large-screen {
      border-left: solid 1px $light-grey;
      border-right: solid 1px $light-grey;
    }

    &.empty {
      border: 0;
    }

    .no-appointments {
      width: 75%;
      text-align: center;
      margin: 0 auto;
      color: $grey;
    }
  }

}