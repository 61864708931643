header{
  width:100%;
  @include flex-column-wrap;
  align-items: center;
  padding:20px 0;
  img{
    width:18%;
    margin-bottom:15px;
    max-width:90px;
  }
  h1{
    font-size:1.6rem;
    font-family: League Spartan;
    text-transform: uppercase;
    margin-bottom:25px;
  }
  nav{
    width:100%;
    ul{
      @include flex-wrap;
      justify-content: center;
      a{
        text-decoration: none;
        color:white;
        li{
          padding:10px 20px;
          background-color: $black;
          pointer-events: none;
          transition:background-color .2s;
          height: 100%;
          @include flex-column-wrap;
          justify-content: center;
          svg{
            height:2rem !important;
            vertical-align: middle;
          }
        }
        &:hover{
          li{
            background-color:$red;
          }
        }
        &.nav-active{
          li{
            background-color:$red;
          }
        }
      }
    }
    
  }
}