.prospects {
  .search-bar {
    width: 75%;
    max-width: 400px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px $grey;

    &:focus {
      outline-color: $black;
    }
  }

  .new-prospect {
    margin-bottom: 20px;
    cursor: pointer;
    @include flex-wrap;
    background: transparent;
    border: 0;
    outline: 0;
    align-items: center;
    justify-content: center;
    transition: opacity .1s;

    span {
      background-color: $green;
      color: white;
      @include flex-wrap;
      width: 2.8rem;
      height: 2.8rem;
      justify-content: center;
      align-items: center;
      border-radius: 99999px;
      margin-right: 8px;
      transition: background-color .1s, color .1s;

      svg {
        height: 1.6rem;
      }
    }

    &:hover {
      opacity: .8;

      span {
        color: $green;
        background-color: transparent;
      }
    }
  }

  .prospects-editor {
    @include popup;

    .editor-content {
      h3 {
        margin-bottom: 20px;
      }

      form {
        @include form;
      }
    }
  }
}